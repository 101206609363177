/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


.transparent-modal{
    .modal-wrapper{
        --background: rgba(44, 39, 45, 0.2);
    }
}

.rounded-edges-modal .modal-wrapper {
  border-radius: 20px;

}

.loading-css-class{
  --background:url("/assets/video/loader.gif");
  --spinner-color:#1b7c23;
  --backdrop-opacity: 30%;
  --border-radius: 10px;
  --height: 100px;
  --max-width: 300px;
  --width: 250px;
}
.loading-css-class .loading-wrapper{
  border-radius: 10px;

}


@media (max-width: 768px) {

.rounded-edges-modal .modal-wrapper {
  border-radius: 0px;

}
}
@media (max-width: 600px){
  .loading-css-class{
    --background:url("/assets/video/media-loader.gif");
    --spinner-color:#1b7c23;
    --backdrop-opacity: 30%;
    --border-radius: 10px;
    --height: 50px;
    --max-width: 300px;
    --width: 200px;
  }
}
